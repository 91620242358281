@import '../../../../styles/customMediaQueries.css';

.root {
  border-top: 1px solid var(--colorGrey100);
}

.footerContainer {
  background-color: #fff !important;
  padding: 0px;

  & > div {
    padding: 36px 24px 119px;

    @media (--viewportLarge) {
      padding: 36px 0px 119px;
    }

    & .footer {
      & .content {
      }
    }
  }

  & .sloganDesktop {
    margin-bottom: 15px;
    max-width: 100%;
    width: 100%;
  }

  & .slogan {
    color: #000;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  & .copyright {
    color: var(--colorWhite);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #618741;
    padding: 37px 24px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  & .icons {
    & > div {
      width: 45px;
      height: 45px;
      border-radius: 100px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: #618741;
      padding: 10px;
      transition: var(--transitonStyle);
      cursor: pointer;

      &:hover {
        transition: var(--transitonStyle);
        background-color: var(--marketplaceColor);
      }

      &:not(:last-child) {
        margin-right: 5px;
      }

      & > a {
        padding: 0px !important;

        & > svg {
          fill: var(--colorWhite);
        }
      }
    }
  }

  & p {
    margin-top: 30px;

    & strong {
      color: #000000;
      display: block;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 36px;
      margin-bottom: 0;
    }
    & em {
      visibility: hidden;
    }
  }

  & ul {
    margin-top: 20px;

    & > li {
      list-style-type: disc;
      color: #000;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      & > a {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        line-height: 32px;
        text-align: left;
        color: #000000;
        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 24px;
        }
        &:hover {
          color: var(--colorBlack);
        }
      }
    }
  }
}

.footer {
  max-width: calc(1243px + 64px);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0px 32px;

  @media (max-width: 768px) {
    margin-bottom: 40px;
    padding: 24px 0;
  }
}

.footer ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-top: 16px;
}

.linkList {
  list-style-type: none;
  padding-left: 12px;
}

.content {
  display: grid;
  justify-content: center;
  align-content: end;
  grid-auto-rows: auto;
  grid-gap: 32px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'logo'
    'details'
    'grid'
    'links';
}

.grid {
  grid-area: grid;
  display: grid;
  justify-content: center;
  align-content: start;
  grid-auto-rows: auto;
  grid-gap: 32px;
}

.gridCol1,
.gridCol2,
.gridCol3,
.gridCol4 {
  grid-template-columns: 1fr;
}

.detailsInfo {
  grid-area: links;
}

.slogan {
  font-size: 16px;
  line-height: 24px;
}

.sloganMobile {
  grid-area: details;
  padding-bottom: 24px;

  @media (--viewportLarge) {
    display: none;
  }
}

.sloganDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.logo {
  grid-area: logo;
}

.copyright {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  color: var(--colorGrey500);
  margin-top: 24px;
}

.icons {
  margin-top: 24px;

  @media (--viewportLarge) {
    margin-top: 0px;
  }
}
:global(#terms-and-privacy){
  @media (max-width: 1023px) {
    margin-top: 16px;
    & p{
      display: none;
    }
  }
}
.icon {
  font-size: 28px;
  margin: 15px 15px 0px 0px;
  padding: 10px 15px;
}

.logoLink {
  display: inline-block;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}

.logoWrapper {
  max-width: 370px;
}

.logoImage {
  height: auto;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  object-position: center;

  @media (--viewportLarge) {
    max-width: min(100%, 298px);
  }
}

@media (min-width: 350px) {
  .gridCol2,
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
  }
}

@media (min-width: 600px) {
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (--viewportMedium) {
  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (--viewportLarge) {
  .detailsInfo {
    grid-area: details;
  }

  .contentCol1 {
    /* 1/6 of space is for grid, and grid uses 1 column internally */
    grid-template-columns: 2fr 3fr 1fr;
    grid-template-areas:
      'logo . grid'
      'details . grid'
      'details . grid'
      'details . grid';
  }

  .contentCol2 {
    /* 2/6 of space is for grid, and grid splits it to 2 columns */
    grid-template-columns: 2fr 2fr 2fr 2fr;
    grid-template-areas:
      'logo . grid grid'
      'details . grid grid'
      'details . grid grid'
      'details . grid grid';
  }

  .contentCol3 {
    /* 3/6 of space is for grid, and grid splits it to 3 columns */
    grid-template-columns: 2fr 1fr 1fr 2fr;
    grid-template-areas:
      'logo . grid'
      'details . grid'
      'details . grid'
      'details . grid';
  }

  .contentCol4 {
    /* 4/6 of space is for grid, and grid splits it to 4 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . grid'
      'details . grid'
      'details . grid'
      'details . grid';
  }

  .gridCol1 {
    grid-template-columns: 1fr;
  }

  .gridCol2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .gridCol3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1260px) {
  .contentCol2 {
    /* 2/6 of space is for grid, and grid splits it to 2 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . grid grid'
      'details . grid grid'
      'details . grid grid'
      'details . grid grid';
  }
}
@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }

  &:target {
    scroll-margin-top: var(--topbarHeight);

    @media (--viewportMedium) {
      scroll-margin-top: var(--topbarHeightDesktop);
    }
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;
  word-break: break-word;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.savingsWrapper {
  margin: 60px 0;

  &>div {
    align-items: center;
    max-width: 1260px;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;

    &>div {
      &:first-child {
        &>div {
          & h3 {
            font-family: 'Poppins';
            font-size: 48px;
            font-weight: 600;
            line-height: 72px;
            text-align: left;
            color: #000000;
          }

          & p {
            font-family: 'Poppins';
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            text-align: left;
            color: #000000;
          }
        }
      }
    }
  }
}

.howitWorks {
  margin-top: 100px;
  margin-bottom: 0px;

  & header {
    & h1 {
      font-family: 'Poppins';
      font-size: 48px;
      font-weight: 600;
      line-height: 72px;
      text-align: left;
      color: #000000;
    }
  }

  &>div {
    padding-top: 50px;

    &>div {
      &:last-child {
        &>div {
          &>div {
            &:first-child {}

            &:last-child {
              /* & p { */
              position: relative;
              bottom: 100px;
              padding: 0 15px;
              font-family: 'Poppins';
              font-size: 18px;
              font-weight: 500;
              line-height: 27px;
              text-align: left;
              color: #fff;
            }

            /* } */
          }
        }
      }
    }
  }
}

.scheduleButton {
  margin-bottom: 100px;

  & header {
    & a {
      margin-top: 0;
      font-family: 'Poppins';
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      text-align: left;
      padding: 14px 24px;
      border-radius: 10px;
    }
  }
}

.aboutusWrapper {
  &>div {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 800px;

    & header {
      @media(min-width:600px) {
        position: absolute;
        left: 50%;
        bottom: -20px;
        transform: translate(-50%, -50%);
        max-width: 1260px;
        width: 100%;
        display: block;
      }

      & h2 {
        font-family: 'Poppins';
        font-size: 48px;
        font-weight: 600;
        line-height: 72px;
        text-align: left;
        color: var(--colorWhite);
      }

      & p {
        font-family: 'Poppins';
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        color: var(--colorWhite);
        max-width: 100%;
      }
    }
  }
}

.ourStoryWrapper {
  &>div>div {
    background-color: #fff;
    gap: 10px !important;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 !important;
    border-radius: 20px;

    &>div {
      &:first-child {
        &>div {
          padding-left: 30px;
          padding-bottom: 60px;

          & h3 {
            color: #000000;
            font-family: 'Poppins';
            font-size: 24px;
            font-weight: 400;
            line-height: 36px;
            text-align: left;
            padding-top: 60px;
          }

          &>p {
            &:nth-of-type(1) {
              font-family: 'Poppins';
              font-size: 32px;
              font-weight: 600;
              line-height: 48px;
              text-align: left;
              color: #000000;
            }

            &:nth-of-type(2),
            &:nth-of-type(3) {
              font-family: 'Poppins';
              font-size: 18px;
              font-weight: 400;
              line-height: 27px;
              text-align: left;
              color: #000000;
            }
          }
        }
      }

      &:last-child {
        height: 100%;
      }
    }
  }
}

.chooseUsWrapper {
  margin: 40px 0;

  & header {
    justify-content: left;

    & h2 {
      font-family: 'Poppins';
      font-size: 32px;
      font-weight: 600;
      line-height: 48px;
      text-align: left;
    }
  }

  &>div {
    padding: 50px 0 0 0;
  }

  & :global(#choose-images) {
    &>div {
      &:first-child {
        z-index: 1;
        position: relative;
      }

      &:last-child {
        background-color: var(--marketplaceColor);
        margin-top: -20px;
        z-index: 10;
        position: relative;
        border-radius: 10px;
        padding: 16px 20px;
        min-height: 260px;

        & h3 {
          font-family: 'Poppins';
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          text-align: left;
          color: #fff;
        }

        & p {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #fff;
        }
      }
    }
  }
}
.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}
.textContent {
  position: relative;
  opacity: 0;
  transform: translateY(80px); /* Move 80px down */
  transition: transform 1s ease, opacity 1s ease;
}

.inView {
  opacity: 1;
  transform: translateY(0); /* Bring back to original position */
}
.imageCard {
  overflow: hidden;
  opacity: 0;
  transition: transform 0.6s ease, opacity 0.6s ease;
  transform: translateX(-100%);
}

.imageCardRight {
  transform: translateX(100%) !important; 
}
.imageCardTop {
  transform: translateY(-100%) !important; 
}
/* Left to right animation */
.inViewPosition {
  opacity: 1;
  transform: translateX(0) !important;
}
.noAnimation{
  transform: unset !important;
}
:global(#hero_section) {
  & > div {
    &:nth-child(1) {
      & > div {
        background-color: #ffffff !important;
        opacity: 0 !important;
      }
    }
    &:nth-child(2) {
      padding: 80px 0;
      @media (max-width: 767px) {
        padding: 48px 0;
      }
      & > div {
        max-width: calc(1248px + 48px);
        padding: 40px 24px 0 24px;
      }
    }
  }
  & img {
    min-height: 180px;
    object-fit: contain;
    @media (max-width: 767px) {
      object-fit: cover;
    }
  }
  & h1 {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 700;
    line-height: 78px;
    text-align: center;
    color: #000;
    max-width: 100%;
    text-wrap: pretty;
    @media (max-width: 1200px) {
      font-size: 42px;
      line-height: 55px;
    }
    @media (max-width: 767px) {
      font-size: 36px;
      line-height: 55px;
    }
    @media (max-width: 450px) {
      font-size: 28px;
      line-height: 45px;
    }
  }
  & p {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    text-align: center;
    color: #000;
    max-width: 100%;
    margin: 0;
    padding: 0;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 30px;
    }
  }
  & a {
    margin-top: 40px;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #fff;
    border-radius: 10px;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 28px;
      height: 48px;
    }
  }
  & header {
    max-width: calc(1192px + 48px);
    padding: 0 24px;
    justify-items: center;
  }
  & :global(#hero_section-block-1) {
    & > div > div > div > div {
      padding-bottom: 31% !important;
      @media (max-width: 767px) {
        padding-bottom: 60% !important;
      }
    }
  }
}
:global(#take_control_section) {
  & > div {
    &:nth-child(2) {
      padding: 80px 0;
      @media (max-width: 1023px) {
        padding: 60px 0;
      }
    }
    & > div {
      max-width: calc(1162px + 64px);
      gap: 0;
      display: flex;
      @media (max-width: 1023px) {
        flex-direction: column;
        padding: 0 24px;
      }
      & > div {
        &:nth-child(1) {
          width: 49%;
          @media (max-width: 1023px) {
            width: 100%;
          }
        }
        &:nth-child(2) {
          width: 51%;
          @media (max-width: 1023px) {
            width: 100%;
          }
        }
      }
    }
  }
  & h3 {
    font-family: Poppins;
    font-size: 44px;
    font-weight: 600;
    line-height: 66px;
    text-align: left;
    color: #fff;
    padding-bottom: 10px;
    @media (max-width: 1023px) {
      font-size: 30px;
      line-height: 38px;
    }
  }
  & p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #fff;
    padding: 0;
    @media (max-width: 1023px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  & a {
    height: 70px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-top: 60px;
    @media (max-width: 1023px) {
      height: 56px;
      font-size: 16px;
      line-height: 22px;
    }
  }
  & img {
    border-radius: 0;
  }
  & :global(#take_control_section-block-1) {
    padding: 90px 17px 40px 40px;
    border-radius: 20px 0px 0px 20px;
    background-color: #e8e7e5;
    background: linear-gradient(
      142.42deg,
      rgba(255, 255, 255, 0.5) -1.15%,
      rgba(255, 255, 255, 0.3) 50.26%,
      rgba(255, 255, 255, 0.5) 101.92%
    );
    backdrop-filter: blur(8px);
    @media (max-width: 1023px) {
      border-radius: 20px 20px 0 0;
      padding: 36px 20px;
    }
    & > div {
      &:nth-child(2) {
        margin: 0;
      }
    }
  }
}
:global(#why_choose_ankyr) {
  background: #f6f6f6e5;
  & > div {
    @media (min-width: 768px) {
      padding: 60px 0;
    }
    & > div {
      max-width: calc(1242px + 64px);
      @media (max-width: 767px) {
        padding: 0 24px;
      }
      @media (min-width: 768px) {
        gap: 30px;
        grid-template-columns: 1fr;
      }
      @media (min-width: 900px) {
        gap: 70px;
        grid-template-columns: repeat(2, calc((100% - 70px) / 2));
      }
    }
  }
  & :global(#why_choose_ankyr-block-1) {
    padding-top: 50px;
    @media (max-width: 767px) {
      padding-top: 24px;
    }
  }
  & :global(#why_choose_ankyr-block-2) {
    & > div {
      &:nth-child(2) {
        margin: 0;
      }
    }
  }
  & h3 {
    font-family: Poppins;
    font-size: 54px;
    font-weight: 600;
    line-height: 81px;
    text-align: left;
    color: #000;
    padding: 0;
    @media (max-width: 1200px) {
      font-size: 40px;
      line-height: 52px;
    }
    @media (max-width: 767px) {
      font-size: 30px;
      line-height: 42px;
    }
  }
  & p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    margin: 0 0 20px;
    padding: 0;
    color: #000;
    @media (max-width: 1200px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  & a {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #fff;
    height: 53px;
    margin: 20px 0 0 34px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    @media (max-width: 1023px) {
      font-size: 16px;
    }
  }
  & ul {
    padding-inline-start: 34px;
    list-style-type: none;
    & li {
      padding: 0;
      position: relative;
      &:before {
        background: #054f31;
        position: absolute;
        height: 18px;
        width: 18px;
        content: '';
        border-radius: 100px;
        left: -34px;
        top: 5px;
      }
      & p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #000;
        padding: 0;
        margin: 0 0 26px;
        @media (max-width: 1200px) {
          font-size: 14px;
          line-height: 22px;
        }
        & strong {
          font-family: Poppins;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          text-align: left;
          color: #000;
          display: block;
          @media (max-width: 1200px) {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
}
:global(#how_it_works) {
  background: #f6f6f6e5;
  & > div {
    padding: 0;
  }
  & img {
    border-radius: 0;
  }
  & :global(#get_started_button) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
  }
  & :global(#how_it_works-container) {
    padding-top: 34px;
  }
  & :global(#how_it_works-slider) {
    overflow-y: unset;
    padding: 0;
    & > div {
      margin-right: 24px;
      & > div {
        &:nth-child(2) {
          position: relative;
          width: calc(100% - 50px);
          background: #f8f8f8;
          padding: 20px;
          margin-top: -50px;
          margin-left: -1px;
          @media (max-width: 1023px) {
            width: calc(100% - 24px);
          }
          & h3 {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            text-align: left;
            color: #000;
            padding: 0;
            @media (max-width: 1023px) {
              font-size: 20px;
            }
          }
          & p {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            padding: 0;
            margin: 12px 0 0;
            @media (max-width: 1023px) {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  & h1 {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 600;
    line-height: 72px;
    text-align: left;
    color: #000;
    @media (max-width: 1023px) {
      font-size: 30px;
      line-height: 45px;
    }
  }
  & p {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: #000;
    padding: 0;
    margin: 10px 0 0;
    @media (max-width: 1023px) {
      font-size: 18px;
      line-height: 30px;
    }
  }
}
:global(#get_started_button) {
  background: #f6f6f6e5;
  & > div {
    padding-top: 0;
    @media (min-width: 767px) {
      padding: 40px 0 60px;
    }
  }
  & a {
    margin-top: 0;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #fff;
    height: 53px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    @media (max-width: 1023px) {
      font-size: 18px;
      line-height: 30px;
    }
  }
}
:global(#new_nar_rules) {
  background-color: #ffffff;
  & > div {
    @media (min-width: 768px) {
      padding: 90px 0;
    }
    & > div {
      max-width: calc(1248px + 64px);
      display: flex;
      gap: 50px;
      @media (max-width: 900px) {
        flex-direction: column-reverse;
      }
      & > div {
        &:nth-child(1) {
          width: 56.45%;
          @media (max-width: 900px) {
            width: 100%;
          }
        }
        &:nth-child(2) {
          width: 40%;
          @media (max-width: 900px) {
            width: 100%;
          }
          & > div {
            &:nth-child(2) {
              margin: 0;
            }
          }
        }
      }
    }
  }
  & h3 {
    margin: 10px 0 20px;
    padding: 0;
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: left;
    color: #000;
    @media (max-width: 1200px) {
      font-size: 28px;
      line-height: 40px;
    }
  }
  & p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #000;
    padding: 0;
    margin: 0;
    max-width: 465px;
    @media (max-width: 1200px) {
      font-size: 16px;
      line-height: 22px;
    }
    & strong {
      font-weight: 500;
    }
  }
  & a {
    margin-top: 75px;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #fff;
    height: 53px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    @media (max-width: 1200px) {
      font-size: 18px;
      line-height: 30px;
      margin-top: 24px;
    }
  }
}
:global(#challenge_traditional_industry) {
  & > div {
    @media (min-width: 768px) {
      padding: 90px 0;
    }
    & > div {
      max-width: calc(1206px + 64px);
      display: flex;
      gap: 70px;
      @media (max-width: 900px) {
        gap: 40px;
        flex-direction: column;
      }
      & > div {
        &:nth-child(1) {
          width: 46%;
          @media (max-width: 900px) {
            width: 100%;
          }
          & > div {
            &:nth-child(2) {
              margin-top: 0;
            }
          }
        }
        &:nth-child(2) {
          width: 50%;
          @media (max-width: 900px) {
            width: 100%;
          }
        }
      }
    }
  }
  & h3 {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 600;
    line-height: 72px;
    text-align: left;
    padding: 0;
    color: #000;
    @media (max-width: 1200px) {
      font-size: 40px;
      line-height: 50px;
    }
    @media (max-width: 767px) {
      font-size: 28px;
      line-height: 40px;
    }
  }
  & p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #000;
    padding: 0;
    margin: 10px 0 0;
    max-width: 546px;
    @media (max-width: 1200px) {
      font-size: 16px;
      line-height: 22px;
    }
    & strong {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      text-align: left;
      margin: 0 0 20px;
      display: block;
      @media (max-width: 1200px) {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
  & a {
    margin-top: 100px;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #fff;
    height: 53px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    @media (max-width: 1200px) {
      font-size: 18px;
      line-height: 30px;
      margin-top: 48px;
    }
  }
}

:global(#about_us_section) {
  & > div {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 685px;

    & header {
      @media (min-width: 200px) {
        position: absolute;
        left: 50%;
        bottom: -112px;
        transform: translate(-50%, -50%);
        max-width: 1260px;
        width: 100%;
        display: block;
      }

      & h2 {
        font-family: 'Poppins';
        font-size: 48px;
        font-weight: 600;
        line-height: 72px;
        text-align: left;
        color: var(--colorWhite);
        padding: 0;
        @media (max-width: 767px) {
          font-size: 30px;
          line-height: 42px;
        }
      }

      & p {
        font-family: 'Poppins';
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        color: var(--colorWhite);
        max-width: 100%;
        padding: 0;
        margin: 20px 0 0;
        text-wrap: unset;
        @media (max-width: 1200px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
  & a {
    height: 70px;
    width: fit-content;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    @media (max-width: 1200px) {
      height: 53px;
      font-size: 18px;
      margin-top: 24px;
    }
  }
}
:global(#our_story_section) {
  & img {
    border-radius: 0;
    @media (max-width: 1023px) {
      border-radius: 0 0 20px 20px;
    }
  }
  & > div {
    &:nth-child(2) {
      padding-left: 24px;
      padding-right: 24px;
      @media (min-width: 768px) {
        padding: 80px 24px;
      }
    }
  }
  & > div > div {
    background-color: #fff;
    gap: 18px !important;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 !important;
    border-radius: 20px;
    display: flex;
    max-width: 1162px;
    @media (max-width: 1023px) {
      flex-direction: column;
    }
    & > div {
      &:first-child {
        width: 47.5%;
        @media (max-width: 1023px) {
          width: 100%;
        }
        & > div:nth-child(2) {
          padding-left: 30px;
          padding-bottom: 80px;
          margin-top: 0;
          @media (max-width: 767px) {
            padding: 0 20px 30px;
          }
          & h3 {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 400;
            line-height: 36px;
            text-align: left;
            padding: 80px 0 0;
            margin: 0 0 10px;
            @media (max-width: 1200px) {
              font-size: 20px;
              line-height: 32px;
              padding: 36px 0 0;
            }
          }

          & p {
            padding: 0;
            max-width: 511px;
            & strong {
              font-family: Poppins;
              font-size: 32px;
              font-weight: 600;
              line-height: 48px;
              text-align: left;
              margin: 0 0 10px;
              display: block;
              @media (max-width: 1200px) {
                font-size: 28px;
                line-height: 39px;
              }
            }

            &:nth-of-type(2),
            &:nth-of-type(3) {
              font-family: 'Poppins';
              font-size: 18px;
              font-weight: 400;
              line-height: 27px;
              text-align: left;
              color: #000000;
              margin: 0;
              padding: 0;
              @media (max-width: 1200px) {
                font-size: 16px;
                line-height: 22px;
              }
            }
            &:nth-of-type(3) {
              margin-top: 24px;
            }
          }
        }
      }

      &:last-child {
        height: 100%;
        width: 51.5%;
        @media (max-width: 1023px) {
          width: 100%;
        }
      }
    }
  }
}
:global(#meet_realtors) {
  background: #fff;
  & header {
    max-width: 1248px;
  }
  & :global(#meet_realtors-block-1),
  & :global(#meet_realtors-block-2),
  & :global(#meet_realtors-block-3) {
    & > div {
      &:nth-child(2) {
        position: relative;
        width: calc(100% - 50px);
        background: #f8f8f8;
        padding: 20px;
        margin-top: -50px;
        margin-left: -1px;
        @media (max-width: 1023px) {
          width: calc(100% - 24px);
        }
      }
    }
  }
  & > div {
    padding: 0;
    & > div {
      max-width: calc(1248px + 64px);
      @media (min-width: 768px) {
        gap: 24px;
        grid-template-columns: repeat(2, calc((100% - 1 * 24px) / 2));
      }
      @media (min-width: 1200px) {
        grid-template-columns: repeat(3, calc((100% - 2 * 24px) / 3));
      }
      @media (max-width: 767px) {
        padding: 64px 24px 0 24px;
      }
      & > div {
        & h3 {
          font-family: Poppins;
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;
          text-align: left;
          color: #000;
          padding: 0;
        }
        & p {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          padding: 0;
          margin: 12px 0 0;
        }
      }
    }
  }
  & img {
    border-radius: 0;
  }
  & :global(#how_it_works-slider) {
    & > div {
      margin-right: 24px;
      & > div {
        &:nth-child(2) {
        }
      }
    }
  }
  & h2 {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 600;
    line-height: 72px;
    text-align: left;
    color: #000;
    max-width: 1248px;
    padding: 0;
    @media (max-width: 1200px) {
      font-size: 40px;
      line-height: 52px;
    }
    @media (max-width: 767px) {
      font-size: 30px;
      line-height: 42px;
    }
  }
  & p {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: #000;
    padding: 0;
    margin: 10px 0 0;
    text-align: center;
    max-width: 1045px;
    text-wrap: pretty;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 30px;
      text-align: left;
    }
  }
  & a {
    margin-top: 37px;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #fff;
    height: 53px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    @media (max-width: 1023px) {
      font-size: 18px;
    }
  }
}
:global(#chance_to_shine) {
  & > div {
    @media (min-width: 768px) {
      padding: 90px 0 80px;
    }
    & > div {
      max-width: calc(1248px + 64px);
      display: flex;
      gap: 50px;
      @media (max-width: 900px) {
        flex-direction: column;
        gap: 30px;
      }
      & > div {
        &:nth-child(1) {
          width: 56.45%;
          @media (max-width: 900px) {
            width: 100%;
          }
        }
        &:nth-child(2) {
          width: 40%;
          @media (max-width: 900px) {
            width: 100%;
          }
          & > div {
            &:nth-child(2) {
              margin: 0;
            }
          }
        }
      }
    }
  }
  & h3 {
    margin: 10px 0 10px;
    padding: 0;
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: left;
    color: #000;
    @media (max-width: 1023px) {
      font-size: 28px;
      line-height: 40px;
    }
  }
  & p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #000;
    padding: 0;
    margin: 0;
    max-width: 465px;
    @media (max-width: 1200px) {
      font-size: 16px;
      line-height: 22px;
    }
    & strong {
      font-weight: 500;
    }
  }
  & a {
    margin-top: 103px;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #fff;
    height: 53px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    @media (max-width: 1200px) {
      font-size: 18px;
      line-height: 30px;
      margin-top: 24px;
    }
  }
}
:global(#testimonials_landing) {
  background-color: #054f31;
  & > div {
    @media (min-width: 768px) {
      padding: 80px 0;
    }
  }
  & header {
    max-width: calc(1158px + 64px);
    justify-content: start;
    & h2 {
      max-width: 100%;
      font-family: Poppins;
      font-size: 48px;
      font-weight: 600;
      line-height: 72px;
      text-align: left;
      color: #fff;
      padding: 0;
      @media (max-width: 1023px) {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
  & :global(#testimonials_landing-container) {
    & > div {
      &:nth-child(1) {
        opacity: 1;
      }
      & button {
        background-color: transparent;
        color: transparent;
        &:nth-child(1) {
          left: 141px;
          bottom: 79px;
          height: 44px;
          width: 44px;
          background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M30.1479 6.4522C30.3057 6.28512 30.4292 6.08859 30.5111 5.87381C30.593 5.65904 30.6318 5.43023 30.6253 5.20045C30.6188 4.97067 30.5671 4.74442 30.4732 4.53462C30.3793 4.32482 30.2449 4.13558 30.0779 3.9777C29.9108 3.81982 29.7142 3.69639 29.4995 3.61446C29.2847 3.53253 29.0559 3.49371 28.8261 3.50021C28.5963 3.50671 28.3701 3.55841 28.1603 3.65234C27.9505 3.74628 27.7612 3.88062 27.6033 4.0477L12.7283 19.7977C12.4212 20.1226 12.25 20.5528 12.25 20.9999C12.25 21.4471 12.4212 21.8773 12.7283 22.2022L27.6033 37.9539C27.7602 38.1247 27.9494 38.2626 28.16 38.3595C28.3705 38.4565 28.5983 38.5107 28.83 38.519C29.0617 38.5272 29.2927 38.4893 29.5096 38.4075C29.7265 38.3257 29.925 38.2016 30.0936 38.0424C30.2622 37.8833 30.3974 37.6922 30.4915 37.4803C30.5856 37.2684 30.6366 37.0399 30.6417 36.8082C30.6467 36.5764 30.6057 36.3459 30.5209 36.1301C30.4361 35.9143 30.3093 35.7176 30.1479 35.5512L16.4068 20.9999L30.1479 6.4522Z' fill='%23054F31'/%3E%3C/svg%3E%0A");
          @media (max-width: 767px) {
            right: 64px;
            bottom: 44px;
            left: unset;
          }
        }
        &:nth-child(2) {
          right: 141px;
          bottom: 79px;
          height: 44px;
          width: 44px;
          background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8521 6.4522C11.6943 6.28512 11.5708 6.08859 11.4889 5.87381C11.407 5.65904 11.3682 5.43023 11.3747 5.20045C11.3812 4.97067 11.4329 4.74442 11.5268 4.53462C11.6207 4.32482 11.7551 4.13558 11.9221 3.9777C12.0892 3.81982 12.2858 3.69639 12.5005 3.61446C12.7153 3.53253 12.9441 3.49371 13.1739 3.50021C13.4037 3.50671 13.6299 3.55841 13.8397 3.65234C14.0495 3.74628 14.2388 3.88062 14.3967 4.0477L29.2717 19.7977C29.5788 20.1226 29.75 20.5528 29.75 20.9999C29.75 21.4471 29.5788 21.8773 29.2717 22.2022L14.3967 37.9539C14.2398 38.1247 14.0506 38.2626 13.84 38.3595C13.6295 38.4565 13.4017 38.5107 13.17 38.519C12.9383 38.5272 12.7073 38.4893 12.4904 38.4075C12.2735 38.3257 12.075 38.2016 11.9064 38.0424C11.7378 37.8833 11.6026 37.6922 11.5085 37.4803C11.4144 37.2684 11.3634 37.0399 11.3583 36.8082C11.3533 36.5764 11.3943 36.3459 11.4791 36.1301C11.5639 35.9143 11.6907 35.7176 11.8521 35.5512L25.5932 20.9999L11.8521 6.4522Z' fill='%23054F31'/%3E%3C/svg%3E%0A");
          @media (max-width: 767px) {
            right: 16px;
            bottom: 44px;
          }
        }
      }
    }
  }
  & :global(#testimonials_landing-slider) {
    overflow-y: unset;
    padding: 0;
    &:after {
      position: absolute;
      content: '';
      height: 188px;
      background-color: #fff;
      width: 100%;
      bottom: 0;
      z-index: 1;
      @media (max-width: 767px) {
        height: 136px;
      }
    }
    & > div {
      display: flex;
      gap: 50px;
      max-width: 1064px;
      z-index: 2;
      margin-left: 200px;
      padding-left: 65px;
      @media (min-width: 1200px) {
        transform: translateX(100px);
      }
      @media (max-width: 1023px) {
        padding-left: 0px;
      }
      @media (max-width: 767px) {
        flex-direction: column;
      }
      & > div {
        &:nth-child(1) {
          width: 38%;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
        &:nth-child(2) {
          width: 60%;
          margin-top: 0;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
      &:last-of-type{
        @media (min-width: 1200px) {
          margin-right: 500px !important;
        }
    
      }
    }
    & h3 {
      font-family: Poppins;
      font-size: 36px;
      font-weight: 600;
      line-height: 54px;
      text-align: left;
      color: #000;
      position: relative;
      padding: 0;
      margin: 0;
      @media (max-width: 1200px) {
        font-size: 30px;
        line-height: 42px;
      }
    }
    & h2 {
      color: #edc700;
      padding: 0;
      line-height: 36px;
      font-size: 36px;
      letter-spacing: 2px;
      margin: 30px 0 55px;
    }
    & p {
      font-family: Poppins;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      text-align: left;
      color: #000;
      padding: 0;
      margin: 2px 0 0;
      & strong {
        font-family: Poppins;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        text-align: left;
        color: #fff;
        @media (max-width: 1200px) {
          font-size: 20px;
          line-height: 30px;
        }
        &:before {
          content: '';
          width: 94px;
          height: 76px;
          display: inline-block;
          margin-right: 20px;
          background-image: url("data:image/svg+xml,%3Csvg width='94' height='76' viewBox='0 0 94 76' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.2029 40.9836H14.6449C16.2797 21.9027 33.7174 10.6378 42.2319 7.39049L37.8043 0C25.2029 5.26292 0 22.0371 0 47.0304C0 72.0237 16.8019 76.7043 25.2029 75.9205C31.3333 76.0324 43.5942 72.6955 43.5942 58.452C43.5942 44.2085 31.3333 40.8716 25.2029 40.9836Z' fill='white'/%3E%3Cpath d='M75.6087 40.9836H65.0507C66.6855 21.9027 84.1232 10.6378 92.6376 7.39049L88.2101 0C75.6087 5.26292 50.4058 22.0371 50.4058 47.0304C50.4058 72.0237 67.2077 76.7043 75.6087 75.9205C81.7391 76.0324 94 72.6955 94 58.452C94 44.2085 81.7391 40.8716 75.6087 40.9836Z' fill='white'/%3E%3C/svg%3E%0A");
          @media (max-width: 767px) {
            width: 68px;
            height: 56px;
            background-size: 68px;
            margin-right: 16px;
          }
        }
      }
    }
  }
}
:global(#built_for_you) {
  & > div {
    @media (min-width: 768px) {
      padding: 120px 0;
    }
    & > div {
      max-width: calc(1248px + 64px);
      display: flex;
      gap: 40px;
      @media (max-width: 900px) {
        flex-direction: column;
      }
      & > div {
        &:nth-child(1) {
          width: 40%;
          @media (max-width: 900px) {
            width: 100%;
          }
        }
        &:nth-child(2) {
          width: 56.45%;
          @media (max-width: 900px) {
            width: 100%;
          }
        }
        & > div {
          &:nth-child(2) {
            margin-top: 0;
          }
        }
      }
    }
  }
  & h3 {
    margin: 0px 0 12px;
    padding: 0;
    font-family: Poppins;
    font-size: 48px;
    font-weight: 600;
    line-height: 72px;
    text-align: left;
    color: #000;
    max-width: 360px;
    @media (max-width: 1200px) {
      font-size: 40px;
      line-height: 52px;
    }
    @media (max-width: 767px) {
      font-size: 30px;
      line-height: 42px;
    }
  }
  & p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #000;
    padding: 0;
    margin: 0 0 24px;
    max-width: 507px;
    @media (max-width: 1200px) {
      font-size: 16px;
      line-height: 22px;
    }
    &:last-child {
      margin: 0 0 0;
    }
    & strong {
      font-weight: 500;
    }
  }
  & a {
    margin-top: 103px;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    color: #fff;
    height: 53px;
    border-radius: 10px;
  }
}
:global(#faq-section) {
  & > div {
    &:nth-child(2) {
      padding: 276px 0 80px;
      @media (max-width: 1200px) {
        padding: 176px 0 80px;
      }
      @media (max-width: 767px) {
        padding: 130px 0 60px;
      }
      & > div {
        max-width: calc(916px + 64px);
        padding-left: 97px;
        @media (max-width: 767px) {
          padding-left: 80px;
          padding-top: 24px;
        }
      }
    }
  }
  & > div > div > div > div > div {
    & p {
      position: relative;
      padding: 0;
      margin: 0 0 63px;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #fff;
      max-width: 833px;
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
        margin: 0 0 36px;
      }
      &:last-child {
        margin: 0 0 0;
      }
      & strong {
        &:nth-child(1) {
          font-family: Poppins;
          font-size: 64px;
          font-weight: 400;
          line-height: 96px;
          text-align: left;
          position: absolute;
          left: -96px;
          top: -33px;

          @media (max-width: 1200px) {
            font-size: 40px;
            line-height: 50px;
            left: -65px;
            top: -12px;
          }
          @media (max-width: 767px) {
            font-size: 36px;
            line-height: 46px;
            left: -55px;
          }
        }
        &:nth-child(2) {
          font-family: Poppins;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          text-align: left;
          color: #fff;
          display: block;
          margin: 0 0 10px;
          max-width: 662px;
          @media (max-width: 1200px) {
            font-size: 18px;
          }
          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }
  & h2 {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 600;
    line-height: 72px;
    text-align: left;
    padding: 0;
    @media (max-width: 800px) {
      font-size: 30px;
      line-height: 42px;
    }
  }
  & img {
    object-fit: contain;
    width: 589px;
    height: 528px;
    margin-left: 96px;
    margin-top: -44px;
    @media (max-width: 1200px) {
      width: 489px;
      height: 428px;
    }
    @media (max-width: 1023px) {
      width: 389px;
      height: 328px;
      margin-top: 0px;
      margin-left: 36px;
    }
    @media (max-width: 1023px) {
      width: 289px;
      height: 228px;
      margin-top: 0px;
      margin-left: 24px;
    }
    @media (max-width: 767px) {
      width: 200px;
      height: 180px;
      margin-top: 0px;
      margin-left: 24px;
    }
  }
}

.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero {
  justify-content: flex-start !important;

  &>div {
    &:last-child {
      & header {
        padding: 0 70px;
        display: block;

        @media(max-width:768px) {
          padding: 0 34px;
        }

        & h1 {
          text-align: left;
          font-family: 'Poppins';
          font-size: 64px;
          font-weight: 600;
          line-height: 78px;
          text-align: left;
          margin-bottom: 40px;

          @media(max-width:768px) {
            font-size: 46px;
            line-height: 50px;
          }
        }

        & a {
          border: none;
          font-family: 'Poppins';
          font-size: 22px;
          font-weight: 400;
          line-height: 33px;
          text-align: left;
          border-radius: 10px;
          text-decoration: none;

          &:hover {
            background-color: var(--marketplaceColor);
            color: #fff;
          }
        }
      }
    }
  }
}

.getstartButton {
  transition: var(--transitionStyleButton);
  color: var(--colorWhite);
  line-height: 120%;
  letter-spacing: 0.02em;
  background-color: var(--marketplaceColor);
  border-radius: 10px;
  padding: 12px 16px;
  text-decoration: none;
}
.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}
.container {

}

.textContent {
  position: relative;
  opacity: 0;
  transform: translateY(80px); /* Move 80px down */
  transition: transform 1s ease, opacity 1s ease;
}

.inView {
  opacity: 1;
  transform: translateY(0); /* Bring back to original position */
}

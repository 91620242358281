.root {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  /* we style global elements only because they are generated by markdown processor */
  & a {
    color: var(--colorGrey700);

    &:hover {
      color: var(--marketplaceColor);
      text-decoration: none;
    }
  }

  & ol {
    margin-top: 0px;
    padding-left: 0px;
  }

  & li {
    font-size: 14px;
    color: var(--colorGrey700);
    padding: 0px;
    list-style-position: inside;
  }

  & p {
    padding: 0px;
  }
}
.textContent {
  position: relative;
  opacity: 0;
  transform: translateY(80px); /* Move 80px down */
  transition: transform 1s ease, opacity 1s ease;
}

.inView {
  opacity: 1;
  transform: translateY(0); /* Bring back to original position */
}
.imageCard {
  overflow: hidden;
  opacity: 0;
  transition: transform 0.6s ease, opacity 0.6s ease;
  transform: translateX(-100%);
}

.imageCardRight {
  transform: translateX(100%) !important; 
}
.imageCardTop {
  transform: translateY(-100%) !important; 
}
/* Left to right animation */
.inViewPosition {
  opacity: 1;
  transform: translateX(0) !important;
}